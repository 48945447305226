/*colors*/
$primary-color: #002b5c;

/* font size */
$medium: 30px;
$large: 35px;

/* ======== INTERNAL APP COLORS ========= */
$internal-app-primary-color: #024b81;
$internal-app-primary-light: #3769c1;
$internal-app-secondary-color-light: #f2dcb5;
$internal-app-secondary-color-dark: #8a7153;
$internal-app-body-background: #e4e9f7;
$internal-app-white: #fff;
$internal-app-light: #f6f5ff;
$internal-app-dark: #707070;
$internal-app-border-light: #f4f2f2;
$internal-app-border-dark: #f0eeee;
$internal-app-blue-light: #024b8163;
$internal-app-dark-blue: #303030;

/* ===== FAVORITE MENU ITEM COLOR ============= */
$priority-1: #142b48;
$priority-2: #2d3081;
$priority-3: #6bcaba;
$priority-4: #25669f;
$priority-5: #86c142;

/* ====== REFERRALS & TODO ========= */

//Referral Status
$refstatus-in-progress: #25669f;
$refstatus-pending: #8a7153;
$refstatus-closed-lost: #c73f64;
$refstatus-closed-won: #62d099;
$refstatus-inc-calc-pending: #707070;

//common color for referral
$refcommon-black: #080808;
$refcommon-white: #fff;

//Todo Status
$todoStatus-cancelled: #b7b7b7;
$todoStatus-scheduled: #024b81;
$todoStatus-completed: #059c00;

//Todo State
$todoState-past-due: #ff9797;
$todoState-due-today: #97ffc4;
$todoState-upcoming: #fff197;
$todoState-snoozed: #d4b796;
$todoState-completed: #00000029;
$todoState-cancelled: #303030;
$todoState-redirect: #660066;

//Todo - dashboard
$todo-past-event-border-color: #d91e1e;
$todo-today-event-border-color: #25669f;
$todo-upcoming-event-border-color: #6bcaba;

//Complete & cancel todo event button
$todo-complete-event: #007504;
$todo-cancel-event: #750000;
$todo-complete-event-hover: #d7f5d8;
$todo-cancel-event-hover: #f5d7d7;

//common color for todo
$todo-common-black: #080808;
$todo-common-white: #fff;

//service desk common

$service-desk-status-review: #ff8c00;
$service-desk-status-pending-compliance-approval: #48cae4;
$service-common-black: hsl(0, 0%, 3%);
$service-common-white: #fff;

